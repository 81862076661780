<template>
  <div class="layout-container">
    <div class="l-header">
      <div class="h-inner">
        <router-link exact to="/">
          <div class="h-logo" :style="{ backgroundImage: 'url(' + logo + ')' }"></div>
        </router-link>
        <div class="h-nav">
          <ul>
            <li
              :class="currentIndex == index ? 'current' : ''"
              v-for="(item, index) in tabList"
              :key="index"
              @click="onChangeTab(index, item)"
            >
              <router-link :to="item.href" exact>{{ item.name }}</router-link>
            </li>
          </ul>
        </div>
        <div class="t-location" v-if="defaultBranch"><span class="t-name">中央分校</span></div>
        <router-link class="h-study" to="/my" exact>个人信息</router-link>
        <router-link to="/message">
          <el-badge :max="99" :hidden="messageNum === 0" :value="messageNum" class="h-message"><i class="el-icon-bell"></i></el-badge>
        </router-link>
        <div class="h-info" v-if="user">
          <el-dropdown>
            <span class="el-dropdown-link h-name">
              {{user.nickName}}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" size="small">
				<el-dropdown-item @click.native="href('/my/password')">修改密码</el-dropdown-item>
              <el-dropdown-item @click.native="onLogout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div
            class="h-photo"
            @click="href('/my/edit')"
            :style="'background-image: url(' + user.avatar +');'"
          ></div>
        </div>
		<div class="h-info" v-else  @click="href('/login')">
		  请登录
		</div>
      </div>
    </div>
    <div class="l-content">
      <router-view />
    </div>
    <div class="l-footer">
      <div class="l-inner">
        <div class="i-item1">
          <div class="i-text">
            百牛课堂 是北京易写科技有限公司旗下专注职业技能提升的在线学习平台。
          </div>
          <div class="i-download">
            <div>点击图标下载App</div>
            <el-dropdown class="i-download" placement="top">
              <div class="el-dropdown-link"><div class="i-icon1">Andriod</div></div>
              <el-dropdown-menu slot="dropdown" class="i-QR">
                <span>扫描二维码下载</span>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown class="i-download" placement="top">
              <div class="el-dropdown-link"><div class="i-icon2">IOS</div></div>
              <el-dropdown-menu slot="dropdown" class="i-QR">
                <span>扫描二维码下载</span>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="i-item2">
          <div class="i-text">
            © 1997-2019 北京易写科技有限公司 版权所有。
            <span>关于我们</span>
            <span>联系我们</span>
            <span>帮助中心</span>
          </div>
          <div class="i-contact">
            <span>关注我们:</span>
            <div class="i-icon1"></div>
            <div class="i-icon2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      messageNum:0,
      tabList: [
        {
          name: '首页',
          href: '/'
        },
        {
          name: '课程管理',
          href: '/manager/course/list'
        },
        {
          name: '课程评论',
          href: '/manager/course/comment'
        },
        {
          name: '老师评论',
          href: '/manager/tea/comment'
        },
		
        {
          name: '课时管理',
          href: '/manager/course/time'
        },
        {
          name: '考试阅卷',
          href: '/manager/test/list'
        }
      ],
      logo: require('@/assets/logo.png'),
      currentIndex: 0,
      starts: 4,
      user: null,
      branchId: this.$.data.get('defaultBranch').branchId,
      defaultBranch: this.$.data.get('defaultBranch'),
    };
  },
  computed: {},
  created() {
    this.getBranch();
    this.getUserInfo();
    this.getCozMesList()
    this.getSysMesList()
	setInterval(()=>{
		this.getCozMesList()
	},3000)
  },
  computed: {
    listenMessageState () {
      return this.$store.state.messageNum
    }
  },
  watch: {
    listenMessageState: function (val, oldval) {
      this.messageNum = val
    }
  },
  mounted() {},
  watch: {},
  methods: {
    // 获取课程消息列表
    getCozMesList() {
      this.post(
        '/biz/teacher/msg/unread',
        { },
        e => {
          if (e.code == 200) {
            this.messageNum=e.data
          } else {
            //this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 获取系统消息列表
    getSysMesList() {
      this.get(
        '/biz/teacher/msg/sysMsg/list',
        { pageNum: 1, pageSize: 1000 },
        e => {
          if (e.code == 200) {
            let sysMsg = e.rows;
            sysMsg.forEach((item)=>{
              if(item.viewFlag == 'n'){
                this.messageNum++
              }
            })
          } else {
            //this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 查询分校列表
    getBranch() {
      this.get('/biz/branch/list', {}, e => {
        if (e.code == 200) {
          this.branchList = e.data;
          console.log(this.branchList);
          if (!this.defaultBranch) {
            this.defaultBranch = e.data[0];
            this.branchId = this.defaultBranch.branchId;
            this.$.data.set('defaultBranch', this.defaultBranch);
          }
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 切换 tab
    onChangeTab(index, item) {
      this.currentIndex = index;
    },
    // 获取个人信息
    getUserInfo() {
      this.get('/biz/teacher/personal/getInfo', {}, e => {
        if (e.code === 200) {
          this.user = e.data;
        } else {
          //this.$.ui.toast(e.msg);
        }
      });
    },
    // 退出登录
    onLogout () {
      this.post('/biz/teacher/login/logout', {}, e => {
        if (e.code == 200) {
          window.localStorage.removeItem('Token')
          this.$.ui.toast('退出成功')
          this.user = {}
          setTimeout(()=>{
            this.href('/login')
          },1000)
        } else {
          this.$.ui.toast(e.msg)
        }
      })
    },
  },
  components: {}
};
</script>

<style>
.i-QR {
  width: 168px;
  height: 168px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    text-align: center;
  }
}
</style>
